export default [
  {
    title: 'Pengguna',
    icon: 'UserIcon',
    route: 'sa-users',
    role: 'superadmin',
  },
  {
    title: 'Program Studi',
    icon: 'FolderIcon',
    route: 'sa-department',
    role: 'superadmin',
  },
  {
    title: 'Dosen',
    icon: 'FolderIcon',
    route: 'sa-dosen',
    role: 'superadmin',
  },
]
