export default [
  {
    title: 'Halaman Depan',
    icon: 'HomeIcon',
    route: 'op-home',
    role: 'operator'
  },
  {
    title: 'Profil',
    icon: 'UserIcon',
    route: 'op-profil',
    role: 'operator'
  },
]