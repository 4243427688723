<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="menuItems" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  computed: {
    menuItems() {
      const userData = getUserData()
      const items = navMenuItems.filter(menu => typeof menu.role == 'string' ? menu.role == userData.role : menu.role.includes(userData.role))
      return items
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
